#home-slider .carousel-item img {
  height: 500px;
}

#search-form {
  background-color: transparent;
  position: absolute;
  bottom: 8%;
  right: 10%;
  width: 380px;
  border-radius: 0;
  border-width: 0;
  z-index: 9;
}
#search-form .nav-tabs {
  margin: 0 !important;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.3);
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
}
#search-form .nav-tabs .nav-link.active {
  background-color: gold;
  color: #fff;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
#search-form .nav-tabs .nav-link {
  color: #fff;
  border-radius: 0;
  border-width: 0px;
}
#search-form .tab-content {
  padding: 20px;
  background-color: #fff;
  border: 1px solid gold;
}
#enquiry-model .search-btn,
#search-form .search-btn {
  background-color: gold;
  width: 100%;
  border-radius: 0;
  border-color: gold;
  font-size: 16px;
}

.shade {
  position: absolute;
  height: 500px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  border: 0;
  bottom: 3px;
}
select.form-select,
input.form-control {
  height: 40px;
  font-size: 14px !important;
}
.carousel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.text-right {
  text-align: right !important;
}

@media only screen and (max-width: 768px) {
  #search-form {
    top: 100px;
    width: 100%;
    left: 0;
    /* background: rgba(0, 0, 0, 0.5); */
  }
  .shade {
    display: none;
  }
  #search-form .nav-tabs .nav-link {
    font-size: 12px;
    min-height: 40px;
    padding: 4px;
  }

  input.form-control,
  select.form-select {
    font-size: 12px !important;
    height: 35px;
  }
  #enquiry-model .search-btn,
  #search-form .search-btn {
    font-size: 14px;
  }
}
