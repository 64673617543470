.left_panel {
  padding: 20px 80px;
}

.left_panel h2 {
  color: gold;
}

.left_panel a {
  color: gold;
  text-decoration: none;
}

.left_panel .submit-btn {
  background-color: gold;
  width: 100%;
  border-radius: 0;
  border-color: gold;
  font-size: 16px;
}

#google-map-div {
  height: 500px;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .left_panel {
    padding: 20px;
  }
}
