#why-choose-us .why-left {
  padding: 50px 40px;
}

#why-choose-us .why-left h3 {
  margin: 10px 0;
}

#why-choose-us .why-right {
  padding: 0;
}
