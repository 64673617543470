#main-nav {
  background-color: #000;
}
#main-nav.trasparent-bg,
#main-nav.trasparent-bg .dropdown-menu {
  background-color: rgba(0, 0, 0, 0.8);
}
#main-nav a {
  padding: 10px;
  color: #deb76f;
  font-size: 14px;
  text-transform: uppercase;
}
#main-nav .dropdown-menu,
#main-nav a:hover {
  background-color: transparent;
}

#main-nav .dropdown-menu {
  background-color: #000;
}

#main-nav .navbar-toggler {
  border-color: #fff;
}
#main-nav .nav-link:focus,
#main-nav .nav-link:hover {
  color: #deb76f;
}
.drivezilla-logo {
  width: 300px;
}

#footer {
  background-color: #3a3a3a;
  color: aliceblue;
  padding: 20px 0;
  font-size: 14px;
}
#footer h5 {
  margin-top: 10px;
}
#footer a {
  color: aliceblue;
  padding: 4px 16px;
}
.copyright {
  background-color: #2e2e2e;
  color: #9c9c9c;
  text-align: center;
  padding: 10px;
  font-size: 12px;
}
.copyright a {
  color: #fff;
}

.webniter {
  float: right;
}

@media only screen and (max-width: 768px) {
  .drivezilla-logo {
    width: 200px;
  }
}
