.top-header-section {
  position: relative;
  height: 300px;
  background-size: cover;
  background-image: url("../img/header_bg.jpg");
  background-position: center;
}
.top-header-section h1 {
  text-align: center;
  color: #fff;
  font-size: 50px;
}

.top-header-section .breadcrumb {
  justify-content: center !important;
}

.top-header-section .breadcrumb a,
.top-header-section .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: #fff;
  font-size: 20px;
  text-decoration: none;
}

.div-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.div-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .top-header-section {
    height: 150px;
    padding: 30px 0;
  }
  .top-header-section h1 {
    font-size: 36px;
  }
  .top-header-section .breadcrumb a,
  .top-header-section .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    font-size: 14px;
  }
}
