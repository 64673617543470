#about-us {
  padding: 80px 0;
}
.text-justify {
  text-align: justify;
}

#about-us .feature-icon {
  margin: 10px auto;
  border: 1px solid gold;
  border-radius: 50%;
  padding: 20px 0;
  width: 120px;
  height: 120px;
  text-align: center;
}

#about-us .feature-icon img {
  width: 70px;
}
#about-us .feature-title {
  text-align: center;
  font-size: 14px;
}

#about-us .card-body p {
  text-align: center;
  font-size: 12px;
}
#about-us .feature-card {
  border-color: transparent;
}
#about-us .about-logo {
  width: 400px;
  margin: 0 auto 40px auto;
}

@media only screen and (max-width: 768px) {
  #about-us {
    padding: 40px 20px;
  }
  #about-us .about-logo {
    width: 100%;
  }
}
